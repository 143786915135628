import domReady from '@roots/sage/client/dom-ready';
/**
 * Application entrypoint
 */
domReady(async () => {

  //+ ========= Global Scripts =====================

  // function to get the window width for mobile scripts
  function getWindowWidth() {
    return window.innerWidth;
  }

  var windowWidth = getWindowWidth();

  //* Agent Popup Open & Close -------------

  // Checks to see if the Agent Popup is present on the page. If so, fires function
  const agentPopupPresent = document.getElementsByClassName('single-agent__popup-container').length;

  if (agentPopupPresent) {
    // open agent popup
    document.querySelector('.agent__learn-more').onclick = function (event) {
      event.preventDefault();
      document.querySelector('.single-agent__popup-container').classList.add('agent_popup_open');
      document.querySelector('.single-agent__popup-container').classList.remove('agent_popup_closed');
    }

    // close agent popup
    document.querySelector('.single_agent_popup_close').onclick = function () {
      document.querySelector('.single-agent__popup-container').classList.add('agent_popup_closed');
      document.querySelector('.single-agent__popup-container').classList.remove('agent_popup_open');
    }
    document.querySelector('.popup-overlay').onclick = function () {
      document.querySelector('.single-agent__popup-container').classList.add('agent_popup_closed');
      document.querySelector('.single-agent__popup-container').classList.remove('agent_popup_open');
    }
  }

  //* END Agent Popup Open & Close -------------


  //* Buyer Guide Popup Open & Close -------------

  // Checks to see if the Agent Popup is present on the page. If so, fires function
  const buyersGuidePopupPresent = document.getElementsByClassName('buyers-guide__popup-container').length;

  if (buyersGuidePopupPresent) {
    // open agent popup
    document.querySelector('.grid-block-button-1').onclick = function (event) {
      event.preventDefault();
      document.querySelector('.buyers-guide__popup-container').classList.add('guide_popup_open');
      document.querySelector('.buyers-guide__popup-container').classList.remove('guide_popup_closed');
    }

    // close agent popup
    document.querySelector('.buyers_guide_popup_close').onclick = function () {
      document.querySelector('.buyers-guide__popup-container').classList.add('guide_popup_closed');
      document.querySelector('.buyers-guide__popup-container').classList.remove('guide_popup_open');
    }
    document.querySelector('.buyers__popup-overlay').onclick = function () {
      document.querySelector('.buyers-guide__popup-container').classList.add('guide_popup_closed');
      document.querySelector('.buyers-guide__popup-container').classList.remove('guide_popup_open');
    }
  }

  //* END Buyer Guide Popup Open & Close -------------


  //* Seller Guide Popup Open & Close -------------

  // Checks to see if the Agent Popup is present on the page. If so, fires function
  const sellersGuidePopupPresent = document.getElementsByClassName('sellers-guide__popup-container').length;

  if (sellersGuidePopupPresent) {
    // open agent popup
    document.querySelector('.grid-block-button-2').onclick = function (event) {
      event.preventDefault();
      document.querySelector('.sellers-guide__popup-container').classList.add('guide_popup_open');
      document.querySelector('.sellers-guide__popup-container').classList.remove('guide_popup_closed');
    }

    // close agent popup
    document.querySelector('.sellers_guide_popup_close').onclick = function () {
      document.querySelector('.sellers-guide__popup-container').classList.add('guide_popup_closed');
      document.querySelector('.sellers-guide__popup-container').classList.remove('guide_popup_open');
    }
    document.querySelector('.sellers__popup-overlay').onclick = function () {
      document.querySelector('.sellers-guide__popup-container').classList.add('guide_popup_closed');
      document.querySelector('.sellers-guide__popup-container').classList.remove('guide_popup_open');
    }
  }

  //* END Seller Guide Popup Open & Close -------------


  //* Header secondary menu hover effect ------
  var headerBuySellLink = document.querySelector('.header--buy-sell-link');
  var buySellSubmenu = document.querySelector('.menu-header-submenu-buy-sell-container');

  var headerAgentsLink = document.querySelector('.header--agents-link');
  var agentsSubmenu = document.querySelector('.menu-header-submenu-agents-container');

  // var headerAboutLink = document.querySelector('.header--about-link');
  // var aboutSubmenu = document.querySelector('.menu-header-submenu-about-container');

  function toggleSubmenuClass(element, className) {
    if (element.classList.contains(className)) {
      element.classList.remove(className);
    } else {
      element.classList.add(className);
    }
  }


  var pageHeaderFunctions = document.getElementsByClassName('page-header').length;

  if (pageHeaderFunctions) {
    headerBuySellLink.addEventListener('click', function () {
      toggleSubmenuClass(buySellSubmenu, 'header-submenu-showing');

      // removes class from inactive menu items
      aboutSubmenu.classList.remove('header-submenu-showing');
      agentsSubmenu.classList.remove('header-submenu-showing');

      headerBuySellLink.classList.toggle('menu-item-active');
      headerAgentsLink.classList.remove('menu-item-active');
      // headerAboutLink.classList.remove('menu-item-active');
    });

    headerAgentsLink.addEventListener('click', function () {
      toggleSubmenuClass(agentsSubmenu, 'header-submenu-showing');

      // removes class from inactive menu items
      buySellSubmenu.classList.remove('header-submenu-showing');
      // aboutSubmenu.classList.remove('header-submenu-showing');

      headerAgentsLink.classList.toggle('menu-item-active');
      headerBuySellLink.classList.remove('menu-item-active');
      // headerAboutLink.classList.remove('menu-item-active');
    });

    // headerAboutLink.addEventListener('click', function () {
    //   toggleSubmenuClass(aboutSubmenu, 'header-submenu-showing');

    //   // removes class from inactive menu items
    //   buySellSubmenu.classList.remove('header-submenu-showing');
    //   agentsSubmenu.classList.remove('header-submenu-showing');
    //   headerAboutLink.classList.toggle('menu-item-active');

    //   headerBuySellLink.classList.remove('menu-item-active');
    //   headerAgentsLink.classList.remove('menu-item-active');
    // });
  }

  //* END Header secondary menu effect ------


  // * Mobile Menu Scripts -----------

  // Slide toggle function to be used on sliding elements - Mobile Menu
  function slideToggleMobileMenu(element) {
    var target = document.querySelector(element);
    var isHidden = target.clientHeight === 0;
    var height = target.scrollHeight;

    target.style.overflow = 'hidden';
    // target.style.transitionProperty = 'height';
    // target.style.transitionProperty = 'height';
    target.style.transitionDuration = '0.25s';
    target.style.transitionTimingFunction = 'ease';

    if (isHidden) {
      target.style.height = height + 'px';
      target.style.opacity = '1';
      target.style.marginTop = '1.25rem';
    } else {
      target.style.height = '0px';
      target.style.marginTop = '0';
    }
  }


  // mobile menu animation scripts -----
    var mobileMenu = document.querySelector('.mobile__menu');
    var pageHeader = document.querySelector('.page-header');
    var fullSiteContainer = document.querySelector('body');

    var mobileMenuOpen = document.querySelector('.mobile-menu-toggle');
    var mobileMenuClose = document.querySelector('.mobile-menu__close');
    var mobileMenuItemBuySell = document.querySelector('.menu-item-498 a');
    var mobileMenuItemAgents = document.querySelector('.menu-item-499 a');
    var mobileMenuItemAbout = document.querySelector('.menu-item-500 a');

    mobileMenuItemBuySell.addEventListener('click', function () {
      slideToggleMobileMenu('.menu-item-498 .sub-menu');
      mobileMenuItemBuySell.classList.toggle('mobile-menu-item--active');
    });

    mobileMenuItemAgents.addEventListener('click', function () {
      slideToggleMobileMenu('.menu-item-499 .sub-menu');
      mobileMenuItemAgents.classList.toggle('mobile-menu-item--active');
    });

    mobileMenuItemAbout.addEventListener('click', function () {
      slideToggleMobileMenu('.menu-item-500 .sub-menu');
      mobileMenuItemAbout.classList.toggle('mobile-menu-item--active');
    });

    mobileMenuOpen.addEventListener('click', function () {
      console.log('mobile menu clicked');
      mobileMenu.classList.add('mobile-menu--open');
      fullSiteContainer.classList.add('mobile-menu--open');
      pageHeader.style.display = 'none';
    });

    mobileMenuClose.addEventListener('click', function () {
      mobileMenu.classList.remove('mobile-menu--open');
      fullSiteContainer.classList.remove('mobile-menu--open');
      pageHeader.style.display = '';
    });


  //* END Mobile Menu Scripts -----------


  //* Footer Mobile Scripts ---------

  // Slide toggle function to be used on sliding elements - Footer Menu
  function slideToggleMobileFooter(element) {
    var target = document.querySelector(element);
    var isHidden = target.clientHeight === 0;
    var height = target.scrollHeight;

    target.style.overflow = 'hidden';
    // target.style.transitionProperty = 'height';
    // target.style.transitionProperty = 'height';
    target.style.transitionDuration = '0.25s';
    target.style.transitionTimingFunction = 'ease';

    if (isHidden) {
      target.style.height = height + 'px';
      target.style.opacity = '1';
    } else {
      target.style.height = '0px';
    }
  }


  var footerMenuCompany = document.querySelector('.footer-menu__comp');
  var footerMenuCompanySubmenu = document.querySelector('#menu-footer-menu-company');

  var footerMenuBuySell = document.querySelector('.footer-menu__buy-sell');
  var footerMenuBuySellSubmenu = document.querySelector('#menu-footer-menu-buy-sell');

  var footerMenuAgents = document.querySelector('.footer-menu__agents');
  var footerMenuAgentsSubmenu = document.querySelector('#menu-footer-menu-agents');

  if (windowWidth < 768) {
    footerMenuCompany.addEventListener('click', function () {
      slideToggleMobileFooter('#menu-footer-menu-company');
      footerMenuCompany.classList.toggle('footer-mobile-menu--open');
      footerMenuCompanySubmenu.classList.toggle('footer-mobile-menu--open');
      console.log('yo');
    });

    footerMenuBuySell.addEventListener('click', function () {
      slideToggleMobileFooter('#menu-footer-menu-buy-sell');
      footerMenuBuySell.classList.toggle('footer-mobile-menu--open');
      footerMenuBuySellSubmenu.classList.toggle('footer-mobile-menu--open');
    });

    footerMenuAgents.addEventListener('click', function () {
      slideToggleMobileFooter('#menu-footer-menu-agents');
      footerMenuAgents.classList.toggle('footer-mobile-menu--open');
      footerMenuAgentsSubmenu.classList.toggle('footer-mobile-menu--open');
    });
  }


  //* END Footer Mobile Scripts ---------


});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
